@use 'vars';

.summary {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(2);
    }
}

.snippet {
    @include vars.snippet();
}

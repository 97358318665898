@use 'vars';

.title {
    @include vars.themifyModule() {
        @include vars.text('font-m-bold');
    }
}

.content {
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
        margin-top: vars.marginStep();
    }

    &::first-letter {
        text-transform: uppercase;
    }
}

@use 'vars';

.breadcrumbs {
    @include vars.themifyModule() {
        height: auto;
    }
}

.path {
    @include vars.themifyModule() {
        color: vars.color('typo-link');
    }
}

.breadcrumbs > span:last-child {
    .path {
        @include vars.themifyModule() {
            @include vars.text('font-m-medium');
            margin-top: vars.marginStep(0.5);
            color: vars.color('typo-primary');
            font-size: 18px;
            display: block;
        }
    }
}

.delimiter {
    @include vars.themifyModule() {
        font-size: 0.8rem;
        vertical-align: middle;
    }
}

@use '@nkc-frontend/cat-design/dist/styles/themes';
@use 'sass:map';
@use '@nkc-frontend/cat-design/dist/styles/themify';
@forward '@nkc-frontend/cat-design/dist/styles/themify';
/*
Контент файла import-overrides генерируется скриптом во время сборки приложения. Туда добавляется или удаляется импорт файла
с переопределением констант, если они есть в варианте интерфейса, который собирается
*/
@import 'import-overrides';
$themes-override: () !default;

$themes: (
    base: (
        color: (
            'admin-navlink-bg': rgba(62, 181, 241, 0.7),
            'catalog-sidebar-bg': #f5f7fa,
            'tags-hover-bg': #005bff,
            'icon-default': #c4c4c4,
            'icon-clear': #262626,
            'control-border': #f0f3f8,
            'table-filter-btn-color': #fff,
        ),
        size: (
            'header-height': 80px,
            //высота хедера на странице поиска, когда он не sticky
            'header-search-height': 112px,
            'footer-height': 80px,
            'full-content-max-width': 1400px,
            //часть от максимальной ширины контента, которую должен занимать основной контент
            'main-content-portion': 0.75,
            //то же что и main-content-portion, но в процентах
            'main-content-percentage': 75%,
            'logo-height': 24px,
            'logo-width': 152px,
            'main-search-panel-width': 55vw,
            'admin-min-layout-width': 600px,
            'admin-header-height': 70px,
            'admin-sidebar-width': 250px,
            'catalog-sidebar-width': 400px,
            'catalog-sidebar-min-width': 20%,
            'catalog-content-width': 68%,
            'catalog-content-max-width': 1048px,
        ),
        padding: (
            'header-right': 46px,
            'header-left': 56px,
            'footer': 256px,
            'full-content-min': 56px,
        ),
        zIndex: (
            'catalog-sidebar': 1000,
            'admin-sidebar': 1000,
            'dropdown-default': 1050,
            // rc-trigger default
            'admin-footer': 1100,
            'header': 2000,
            'dropdown-header': 2500,
            'sidepage': 3000,
            'loading-bar': 3100,
            'dropdown': 3100,
            //дропдауны в формах в сайдпейдже
            'modal': 3500,
        ),
        text: (
            'font-header-title': (
                font-weight: 500,
                font-size: 14px,
                line-height: 16px,
            ),
        ),
        transitions: (
            'main-transition-time': 0.3s,
            'main-transition-func': ease,
        ),
        border-radius: (
            'default': 4px,
        ),
        shadow: (
            'no-top': 0px 16px 12px -9px rgb(45 38 38 / 20%),
        ),
        background: (
            'main-page': (
                linear-gradient(
                    181.43deg,
                    #ffffff 1.21%,
                    rgba(255, 255, 255, 0) 98.79%
                ),
                url('/applications/base/images/main-bg.svg'),
            ),
        ),
    ),
);

@mixin mainTransition($property) {
    transition: $property themify.themed('transitions', 'main-transition-time')
        themify.themed('transitions', 'main-transition-func');
}

@mixin adminStyles {
    :global(.admin) {
        @content;
    }
}

@mixin layoutHeight(
    $header-height,
    $footer-height,
    $header-margin,
    $footer-margin
) {
    min-height: calc(
        100% - #{$header-height} - #{$footer-height} - #{$footer-margin} - #{$header-margin}
    );
}

@mixin pageLayoutContentSizes(
    $header-height,
    $footer-height,
    $content-max-width,
    $content-min-padding,
    $header-margin,
    $footer-margin
) {
    @include layoutHeight(
        $header-height,
        $footer-height,
        $header-margin,
        $footer-margin
    );
    width: $content-max-width;
    margin: $header-margin auto 0 auto;

    @media (max-width: #{$content-max-width + $content-min-padding * 2}) {
        width: 100%;
        padding: 0 $content-min-padding;
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin snippet() {
    //эти стили нужны, чтобы создать эффект, когда при ховере сниппет выходит за левую границу
    position: relative;
    @include themify.themifyModule() {
        left: -#{themify.marginStep(3)};
        width: calc(100% + #{themify.marginStep(3)});
    }
}

$result-themes: map.deep-merge($themes, $themes-override);

@include themes.updateThemes($result-themes);

@use 'vars';
$height-search: 48px;

.wrapper {
    height: calc(100% - #{$height-search});
    flex-grow: 1;
    @include vars.themifyModule() {
        padding-bottom: vars.marginStep(3);
        padding-top: vars.marginStep(3);
    }
}

.tree {
    /**/
}

.more {
    @include vars.themifyModule() {
        padding-top: vars.marginStep();
        padding-bottom: vars.marginStep(2);
        margin-left: calc(
            #{vars.themed('size', 'icon-s')} + #{vars.marginStep(0.5)}
        );
    }
}

@use 'vars';

.catalog {
    height: 100%;
    position: relative !important;
}

.item {
    max-height: 100%;
    overflow: auto;
    @include vars.themifyModule() {
        padding-top: vars.marginStep(5);
        padding-bottom: vars.marginStep(10);
    }
}

.sidebar {
    position: sticky;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include vars.themifyModule() {
        height: 100%;
        background: vars.color('catalog-sidebar-bg');
        z-index: vars.themed('zIndex', 'catalog-sidebar');
    }
    width: 100%;
}

@use 'vars';

.header {
    display: flex;
    justify-content: space-between;
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(1);
        margin-top: vars.marginStep(1);
    }
}

.title {
    @include vars.themifyModule() {
        @include vars.text('font-l-bold');
    }
}

.clear-btn {
    @include vars.themifyModule() {
        @include vars.text('font-s-regular');
        margin-right: vars.marginStep(6);
    }
}

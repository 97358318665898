@use 'vars.scss';

.breadcrumbs {
    height: 1.15rem;
}

.delimiter {
    padding: 0 8px;
    font-size: 0.8rem;
    vertical-align: middle;
}

.link {
    line-height: 1.15rem;
    cursor: pointer;
    &:hover {
        @include vars.themifyModule() {
            color: vars.color('link-color');
        }
    }
}

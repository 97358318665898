@use 'vars';

.catalog-layout {
    height: 100%;
}

.header {
    position: relative;
}

.children {
    @include vars.themifyModule() {
        $header-height: vars.themed('size', 'header-height');
        $footer-height: vars.themed('size', 'footer-height');
        height: calc(100% - #{$header-height} - #{$footer-height});
    }
}

.footer {
    /**/
}

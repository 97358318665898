@use 'vars';

.catalog-item {
    /**/
}

.limit-content-width {
    @include vars.themifyModule() {
        max-width: vars.themed('size', 'catalog-content-max-width');
        width: vars.themed('size', 'catalog-content-width');
    }
}

.heading-container {
    @include vars.themifyModule() {
        border-bottom: 1px solid vars.color('control-border');
    }
}

.heading {
    composes: limit-content-width;

    overflow-wrap: break-word;
    @include vars.themifyModule() {
        padding: 0 vars.marginStep(10) vars.marginStep(4) vars.marginStep(10);
    }
}

.body {
    composes: limit-content-width;
    overflow-wrap: break-word;
    @include vars.themifyModule() {
        margin-top: vars.marginStep(5);
        padding-left: vars.marginStep(10);
    }
}

.keywords {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(5);
    }
}

.documents {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(7);
    }
}

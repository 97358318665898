@use 'vars';

.catalog-list {
    @include vars.themifyModule() {
        padding-top: vars.marginStep(1);
    }
    width: 100%;
    flex: 1;
}

.catalog-option {
    display: flex;
    align-items: center;
    min-height: 40px;
    line-height: 16px;
    width: 100%;
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
        font-size: 14px;
        line-height: 30px;
        padding-top: vars.marginStep(1);
        padding-bottom: vars.marginStep(1);
        padding-left: vars.marginStep(3);
    }
    &:hover {
        @include vars.themifyModule() {
            background: rgba(vars.color('control-additional-icon'), 0.2);
            color: vars.color('typo-primary');
        }
    }
}

.catalog-option-check-icon {
    @include vars.themifyModule() {
        color: vars.color('control-primary-bg-default');
    }
}

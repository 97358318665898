@use 'vars';

.catalog-item {
    display: flex;
    width: 100%;
    position: relative;
    @include vars.themifyModule() {
        padding-top: vars.marginStep(2);
        padding-bottom: vars.marginStep(2);
        padding-right: vars.marginStep(2);
    }

    &_selected {
        @include vars.themifyModule() {
            background: rgba(vars.color('control-additional-icon'), 0.2);
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            @include vars.themifyModule() {
                width: vars.marginStep();
                background: vars.color('control-primary-bg-default');
            }
        }
    }
}

.link {
    display: flex;
    flex-grow: 1;
    white-space: nowrap;
    min-width: 0;

    &_no-children {
        @include vars.themifyModule() {
            margin-left: calc(
                #{vars.themed('size', 'icon-s')} + #{vars.marginStep(0.5)}
            );
        }
    }
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    position: relative;
    top: 3px;
}

.toggle {
    @include vars.themifyModule() {
        margin-right: vars.marginStep(0.5);
    }
}

.arrow {
    @include vars.themifyModule() {
        color: vars.color('control-bg-default');
    }
}

.count {
    @include vars.themifyModule() {
        color: vars.color('typo-secondary');
        @include vars.text('font-xs-regular');
        padding-left: vars.marginStep();
    }
    margin-left: auto;
    font-feature-settings: 'tnum';
}

.more {
    @include vars.themifyModule() {
        padding-top: vars.marginStep();
        padding-bottom: vars.marginStep(2);
        margin-left: calc(
            #{vars.themed('size', 'icon-s')} + #{vars.marginStep(0.5)}
        );
    }
}

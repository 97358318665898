@use 'vars';

.catalog-search {
    display: flex;
    align-items: center;
    text-align: left;
    white-space: nowrap;
    @include vars.themifyModule() {
        padding-left: vars.marginStep(4);
    }
}

.catalog-search-input {
    flex: 1;
}

.catalog-filter-btn {
    display: inline-block;
    align-self: flex-end;
    @include vars.themifyModule() {
        margin-left: vars.marginStep(2);
    }
}

.catalog-filter-btn-icon {
    @include vars.themifyModule() {
        color: vars.color('control-bg-default');
    }
}

.catalog-type-select {
    @include vars.themifyModule() {
        padding-left: vars.marginStep(4);
    }
}

.catalog-search-tools {
    align-self: start;
    @include vars.themifyModule() {
        margin-left: vars.marginStep(3);
        margin-right: vars.marginStep(1);
        margin-top: vars.marginStep(2);
    }
}

.catalog-search-danger-message {
    display: block;
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
        color: vars.color('typo-secondary');
        margin-top: vars.marginStep(0.5);
    }
}

@use 'vars';

.sidebar-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    @include vars.themifyModule() {
        color: vars.color('typo-primary');
        margin-top: vars.marginStep(4);
        padding-left: vars.marginStep(7);
    }
}

.search {
    color: white;
}

.sidebar-subtree-panel {
    position: relative !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
